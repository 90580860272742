import { ResultOf } from '@graphql-typed-document-node/core';
import { graphql } from '@libs/graphql-types';

export const coreUserVideoFragment = graphql(/* GraphQL */ `
  fragment CoreUserVideoFragment on UserVideoNode {
    id
    guid
    title
    createdAt
    updatedAt
    purchasedAt
    lastEditedByUser
    lastEditableDate
    business {
      id
      guid
      businessName
    }
    videoTemplateVariant {
      id
      guid
      displayName
      displayDuration
      width
      height
      isEnabled
      videoTemplate {
        id
        isWaymarkAuthorTemplate
        isRetired
      }
    }
  }
`);

export const userVideoApprovalFragment = graphql(/* GraphQL */ `
  fragment UserVideoApprovalFragment on UserVideoApprovalNode {
    id
    guid
    createdAt
    fullName
    approvedAt
  }
`);

export type UserVideoApproval = ResultOf<typeof userVideoApprovalFragment>;

export const userVideoAccountPageFieldsFragment = graphql(/* GraphQL */ `
  fragment UserVideoAccountPageFieldsFragment on UserVideoNode {
    ...CoreUserVideoFragment
    currentConfiguredVideo {
      id
      thumbnailUrl
    }
    purchaseApproval {
      id
      guid
      createdAt
      approvedAt
    }
    userVideoApprovals {
      ...UserVideoApprovalFragment
    }
    voiceOverProduct {
      id
      isDelivered
    }
    derivedUserVideos {
      totalCount
    }
    originalUserVideo {
      id
      guid
      title
    }
    omniType
  }
`);

export type AccountPageUserVideo = ResultOf<typeof userVideoAccountPageFieldsFragment>;

export const userVideoRendersFragment = graphql(/* GraphQL */ `
  fragment UserVideoRendersFragment on UserVideoNode {
    id
    currentConfiguredVideo {
      id
      guid
      renderStatus
      thumbnailUrl
      renderedVideos {
        edges {
          node {
            id
            guid
            renderUrl
            renderFormat
            renderedAt
            renderStatus
            renderSize
            hasWatermark
            createdAt
          }
        }
      }
    }
  }
`);

export const userVideoFeedbackFragment = graphql(/* GraphQL */ `
  fragment UserVideoFeedbackFragment on UserVideoFeedbackNode {
    id
    guid
    rating
    feedbackText
    timeToCreate
  }
`);

export type UserVideoFeedbackRating = 'positive' | 'negative';

export enum UserVideoTimeToCreate {
  LessThan15Minutes = 'less-than-15-minutes',
  From15To30Minutes = '15-to-30-minutes',
  MoreThan30Minutes = 'more-than-30-minutes',
}

export const editorUserVideoFragment = graphql(/* GraphQL */ `
  fragment EditorUserVideoFragment on UserVideoNode {
    id
    guid
    title
    videoConfiguration
    videoTemplateVariant {
      ...EditorVideoTemplateVariantFragment
    }
    purchasedAt
    createdAt
    updatedAt
    lastEditedByUser
    business {
      id
      guid
    }
    voiceOverText
    voiceOverSpeakerGuid
    voiceOverTiming
  }
`);

export type EditorUserVideo = ResultOf<typeof editorUserVideoFragment>;
